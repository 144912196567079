<template>
    <div v-html="html"></div>
</template>
<script>
import api from "../../api/ztb";
import { Dialog } from 'vant';
// import { dir } from "console";
export default {
    data() {
        return {
            html: '',
        }
    },
    // created() {
    //     this.fetchVideoPay();
    // },
    methods: {
        fetchVideoPay() {
            // const newTab = window.open();
            let param = {
                // orderJg: this.$route.query.jg,
                // price: this.$route.query.price,
                // userId: this.$route.query.userId,
            };
            api.postWachPay3(param).then(res => {
                this.html = res.data;
                console.log(res.data)
                console.log("----------------------ddd")
                // console.log(res.data.alipay_url)
                // this.$nextTick(() => {
                //     document.forms[0].submit()   //渲染支付宝支付页面
                // })

                // window.open(res.data.alipay_url, '_ blank')

                // const div = document.createElement('div');
                // div.innerHTML = res.data.alipay_url; // html code
                // document.body.appendChild(div);
                // document.forms.alipaysubmit.submit();
                

                // let divForm = document.getElementsByTagName('div')
                // if (divForm.length) {
                //     document.body.removeChild(divForm[0])
                // }
                // const div = document.createElement('div')
                // div.innerHTML = res.data // res.data就是sb支付宝返回给你的form
                // document.body.appendChild(div)
                // // document.forms[0].setAttribute('target', '_blank') // 加了_blank可能出问题所以我注释了
                // document.getElementById('alipay_submit').submit()
            })
        },
        //发送绑定
        submitFormUser(params) {
            const newTab = window.open();
            api.postAlipay(params).then((res) => {
                console.table(res);
                if (res.status == 200) {
                    // this.$toast("绑定成功！")
                    console.log("=============00000000000000000000000");
                    console.log(res.data)
                    // window.open(innerHtml(res.data), "_blank");
                    // this.sqfk()

                    // window.open(res.data.innerHTML, '_ blank')

                    const div = document.createElement('div');
                    div.innerHTML = res.data; // html code
                    document.body.appendChild(div);
                    document.forms.alipaysubmit.submit();
                } else {
                    console.log("=============33333333333333333333333");
                }
            });
        },
    },
    mounted() {
        this.fetchVideoPay()
    }
}
</script>